import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import LocalMall from '@material-ui/icons/LocalMall';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Dialog from '../../modules/components/Dialog';
import app from "../../firebase/base";
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from "moment";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selector'
import { selectCurrentSalesman } from '../../redux/salesman/salesman.selector'
import { selectCartItems, selectCartTotal } from '../../redux/cart/cart.selectors'
import { setCurrentUser } from '../../redux/user/user.actions';


const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  logo: {
    height: 60,
    margin: 10
  }
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const [ menuOpen, setMenuOpen ] = React.useState(false);
  const anchorRef = React.useRef(null);

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");
  const [client, setClient] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  useEffect(() => {

    let salesRef = app.database().ref("users");

    salesRef.on('value', snapshot => {
      
      if(snapshot.exists()){
        let _client = [];

        snapshot.forEach(child => {
          var itemsVal = child.val();
          if( props.currentSalesman && itemsVal.salesman_in_charge === props.currentSalesman.id){
            _client.push(itemsVal)
          }
          
        })

        setClient(_client)
      }
    })


    var itemsQuantity = 0;
    var _totalAmount_price1 = 0;
    var _totalAmount_price2 = 0;
    props.cartItems.forEach(val => {
      itemsQuantity += 1*val.quantity
      _totalAmount_price1 += val.sale_price*val.quantity
      _totalAmount_price2 += val.sale_price_2*val.quantity
    })

    var total_purchase = props.currentUser.total_ordered_products ? props.currentUser.total_ordered_products + itemsQuantity : itemsQuantity;
    
    var now = moment();
    var expired_date = moment(props.currentUser.last_purchased).add(60, 'days');

    if(now.isBefore(expired_date) && total_purchase > 499){
      setTotalAmount(_totalAmount_price2)
    }
    else{
      setTotalAmount(_totalAmount_price1)
    }

  }, [props]);

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleMenuClick = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const handleAgree = () => {
    if(option === "logout"){
      app.auth().signOut();
    }

    setOpenDialog(false)
  }

  const handleSignout = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Logout")
    setOptions("logout")
    setDialogDes("You will be returned to the login screen")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const handleChangeClient = (event) => {
    var currentIndex = client.map(val => val.id).indexOf(event.target.value)

    if(currentIndex !== -1){
      app.database().ref("users/" + props.currentSalesman.id).update({ selected_store: client[currentIndex].id})
      props.setCurrentUser(client[currentIndex]);
    }
    else
    {
      app.database().ref("users/" + props.currentSalesman.id).update({ selected_store: null })
      props.setCurrentUser(props.currentSalesman);
    }

    
    
  }

  const renderCartButton = () => {
    return(
      <Button
          variant="outlined"
          style={{ color: lightColor, marginRight: 20, paddingRight: 30 }} onClick={()=>onDrawerToggle("cart")}
          
          endIcon={
          <StyledBadge badgeContent={props.cartItems.length} color="secondary">
          <LocalMall style={{ fontSize: 18 }} />
        </StyledBadge>}
        >
          {"RM " +totalAmount}
        </Button>
    )
  }

  return (
    <React.Fragment>
      <Dialog 
        agreeTxt={"Yes"}
        disagreeTxt={"No"}
        description={dialogDes}
        title={dialogTitle}
        open={openDialog} 
        agreeButton={agreeButton}
        fullScreenDialog={fullScreenDialog}
        option={option}
        dialogLoading={dialogLoading}
        handleAgree={handleAgree}
        handleClose={handleClose}/>

      <AppBar style={{ backgroundColor: "#1c1c1c" }} position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center" style={{ display: "flex", justifyContent: "space-between"}} >
            <Hidden smUp>
              <Grid item>
                <IconButton
                  style={{ color: lightColor}}
                  aria-label="open drawer"
                  onClick={()=>onDrawerToggle("nav")}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <ListItem button component={Link} to={'/'}>
                  <img
                        className={classes.logo}
                        src="/assets/logo/logo-white.png"
                        alt="Sp2s logo"
                      />
                </ListItem>
              </Grid>
              <Grid item>
                <IconButton
                  style={{ color: lightColor}}
                  aria-label="open drawer"
                  onClick={()=>onDrawerToggle("cart")}
                  className={classes.menuButton}
                >
                  <StyledBadge badgeContent={props.cartItems.length} color="secondary">
                    <LocalMall />
                  </StyledBadge>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
              {props.currentSalesman !== null ? <ListItem style={{ width: "100%" }}>
                  <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Store / Agent</InputLabel>
                      <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue=""
                      value={props.currentUser.id ? props.currentUser.id : ""}
                      required
                      label="Status"
                      onChange={handleChangeClient}
                      >
                        <MenuItem value={props.currentUser.id}>{"Yourself (" + (props.currentUser.id ? props.currentUser.id : "") + ")"}</MenuItem>
                      {client.map(val => {
                        return <MenuItem key={val.id} value={val.id}>{val.id}</MenuItem>
                      })}
                      </Select>
                  </FormControl>
                </ListItem> : 
                <Typography style={{ fontSize: 18, marginRight: 10, color: "white" }} variant="caption" color="primary">
                  {props.currentUser.id}
                </Typography>}
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid item style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <ListItem button component={Link} to={'/'}>
                  <img
                        className={classes.logo}
                        src="/assets/logo/logo-white.png"
                        alt="Sp2s logo"
                      />
                </ListItem>
                {props.currentSalesman !== null ? <ListItem style={{ width: 250 }}>
                  <FormControl style={{ minWidth: 250, maxWidth: 250 }}>
                      <InputLabel id="demo-simple-select-label">Store / Agent</InputLabel>
                      <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      defaultValue=""
                      value={props.currentUser.id ? props.currentUser.id : ""}
                      required
                      label="Status"
                      onChange={handleChangeClient}
                      >
                        <MenuItem value={props.currentSalesman.id}>{"Yourself (" + (props.currentUser.id ? props.currentUser.id : "") + ")"}</MenuItem>
                      {client.map(val => {
                        return <MenuItem key={val.id} value={val.id}>{val.id}</MenuItem>
                      })}
                      </Select>
                  </FormControl>
                </ListItem>: 
                <Typography style={{ fontSize: 18, marginRight: 10, color: "white" }} variant="caption" color="primary">
                  {props.currentUser.id}
                </Typography>}
                
              </Grid>
              <Grid item >
                <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}} >
                  {/*<FormControl style={{ height : 40, marginRight: 20, width: 200 }} variant="outlined">
                    <OutlinedInput
                      style={{ height : 40 }}
                      id="outlined-adornment-amount"
                      value={searchTxt}
                      onChange={(e) => search(e)}
                      startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    />
                    </FormControl>*/}
                    

                    <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/redeem_products'}>
                      {"Redeem"}
                      </ListItem>
                    </Typography>
                 
                    <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/offers'}>
                      {"Offers"}
                      </ListItem>
                    </Typography>
                  
                    <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/faq'}>
                      {"FAQ"}
                      </ListItem>
                    </Typography>

                    <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/request'}>
                      {"Request"}
                      </ListItem>
                    </Typography>

                    {props.currentUser.role === "kol" ? <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/generate_agent_id'}>
                      {"Generate Agent ID"}
                      </ListItem>
                    </Typography>: null}

                    {props.currentUser.role === "kol" ? <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/active_agent'}>
                      {"Agent"}
                      </ListItem>
                    </Typography>: null}

                    {props.currentUser.role === "kol" ? <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/generate_store_id'}>
                      {"Generate Store ID"}
                      </ListItem>
                    </Typography>: null}

                    {props.currentUser.role === "kol" ? <Typography style={{ fontSize: 14, marginRight: 10 }} variant="caption" color="primary">
                      <ListItem button style={{ color: lightColor, borderRadius: 5 }}  component={Link} to={'/active_store'}>
                      {"Store"}
                      </ListItem>
                    </Typography>: null}

                    {renderCartButton()}
                  
                  <IconButton ref={anchorRef} color="inherit"  onClick={handleMenuClick}>
                    <Avatar src={props.currentUser.image ? props.currentUser.image.url : ''} />
                  </IconButton>
                  <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleMenuClose}>
                            <MenuList autoFocusItem={menuOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem  component={Link}  to={'/myprofile'}  style={{ fontSize: 14 }} onClick={handleMenuClose}>My Profile</MenuItem>
                              <MenuItem  component={Link}  to={'/myorders'}  style={{ fontSize: 14 }} onClick={handleMenuClose}>My Orders</MenuItem>
                              <MenuItem  component={Link}  to={'/giftredeem'}  style={{ fontSize: 14 }} onClick={handleMenuClose}>Gift Redeem</MenuItem>
                              <MenuItem  style={{ fontSize: 14 }} onClick={handleSignout}>Logout</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            </Hidden>

          </Grid>
        </Toolbar>
      </AppBar>
      
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
});

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  total: selectCartTotal,
  currentUser: selectCurrentUser,
  currentSalesman: selectCurrentSalesman,
})

const routeNavigator = withRouter(Header);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(routeNavigator));

