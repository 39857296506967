import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress';
import Error from "./views/pages/Error";
import withRoot from "./modules/withRoot";
import app from "./firebase/base";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Grid from '@material-ui/core/Grid';

//Client
import NavigationUser from "./views/navigation/NavigationUser";
import Main from "./views/pages/Main";
import OrdersDetails from "./views/pages/ClientPage/OrdersDetails";
import RedeemsDetails from "./views/pages/ClientPage/RedeemsDetails";
import ClientPage from "./views/pages/ClientPage/ClientPage";
import Checkout from "./views/pages/Checkout/Checkout";
import CheckoutRedeem from "./views/pages/Checkout/CheckoutRedeem";
import Offers from "./views/pages/Offers";
import Faq from "./views/pages/Faq";
import Contact from "./views/pages/Contact";
import RegisterUser from "./views/pages/RegisterUser";
import ApprovalUser from "./views/pages/ApprovalUser";
import Agent from "./views/pages/Agent";
import GenerateAgentID from "./views/pages/GenerateAgentID";
import Store from "./views/pages/Store";
import GenerateStoreID from "./views/pages/GenerateStoreID";


import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from './redux/user/user.selector'
import {
  setCurrentUser
} from './redux/user/user.actions';

import {
  setCurrentSalesman
} from './redux/salesman/salesman.actions';

import { useDispatch } from 'react-redux';
import {
  setItem
} from './redux/cart/cart.actions';

import {
  setRedeemItem
} from './redux/redeem/redeem.actions';

import {
  setProductItem
} from './redux/product/product.actions';

import {
  setCategoryItem
} from './redux/category/category.actions';


import ClientLogin from "./views/authentication/ClientLogin";

function App(props) {

  const [authenticated, setAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch()

  useEffect(() => {

    const readCartDatabase = (user) => {
      let cartRef = app.database().ref("carts/"+ user.id);

      cartRef.once('value', snapshot => {
        if(snapshot.exists()){
          dispatch(setItem(snapshot.val().items ? snapshot.val().items : [], user))
        }
        else
        {
          dispatch(setItem([], user))
        }

        let redeemRef = app.database().ref("redeems/"+ user.id);

        redeemRef.once('value', snapshot => {
          if(snapshot.exists()){
            dispatch(setRedeemItem(snapshot.val().items ? snapshot.val().items : [], user))
          }
          else
          {
            dispatch(setRedeemItem([], user))
          }

          let productRef = app.database().ref("products");

          productRef.once('value', snapshot => {
            if(snapshot.exists()){
              let _products = []
              snapshot.forEach(val => {
                _products.push(val.val())
              })

              dispatch(setProductItem(_products))
            }
            else
            {
              dispatch(setProductItem([]))
            }

            let itemCategoriesRef = app.database().ref("categories");

            itemCategoriesRef.once('value', snapshot => {

              if(snapshot.exists()){
                let _categories = []
                snapshot.forEach(val => {
                  _categories.push(val.val())
                })

                dispatch(setCategoryItem(_categories))
              }
              else
              {
                dispatch(setCategoryItem([]))
              }

              setAuthenticated(true)
              setLoading(false)
              
            });

            
          })

          

        })

      })
    }

    let userAllRef = app.database().ref("kol");

    userAllRef.once('value', snapshot => {
      
      let userRef = null;
    
      app.auth().onAuthStateChanged(user => {

        if (user && snapshot.exists()) {
  
          let foundPhone = null;
  
          snapshot.forEach(child=>{
            if(child.val().phone === user.phoneNumber){
              foundPhone = child.val().id;
            }
          })
  
          if(foundPhone)
          {
            userRef = app.database().ref("kol/"+ foundPhone);
  
            userRef.on('value', snapshot => {
              
              if(snapshot.exists()){
                
                if(snapshot.val().role === "kol"){
                  dispatch(setCurrentSalesman(snapshot.val()))
                }
                else{
                  dispatch(setCurrentSalesman(null))
                }
                
    
                if(snapshot.val().selected_store){
                  let storeRef = app.database().ref("kol/"+ snapshot.val().selected_store);
    
                  storeRef.on('value', snapshot_store => {
                    if(snapshot_store.exists()){
                      dispatch(setCurrentUser(snapshot_store.val()))
    
                      readCartDatabase(snapshot_store.val())
                    }else{
                      dispatch(setCurrentUser(snapshot.val()))
    
                      readCartDatabase(snapshot.val())
                    }
    
                    
                  })
                }
                else{
                  dispatch(setCurrentUser(snapshot.val()))
    
                  readCartDatabase(snapshot.val())
                }
              }
              else{
                dispatch(setCurrentUser(null))
                dispatch(setCurrentSalesman(null))
    
                setAuthenticated(true)
                setLoading(false)
              }
    
            })
          }
          else{

            dispatch(setCurrentUser(null))
            dispatch(setCurrentSalesman(null))

            setAuthenticated(true)
            setLoading(false)
          }
          
  
          
         
        } 
        else if(user && !snapshot.exists())
        {
  
          if(userRef){
            userRef.off();
            userRef = null;
          }
  
          setAuthenticated(true)
          setLoading(false)
        }
        else{
          if(userRef){
            userRef.off();
            userRef = null;
          }
  
          setAuthenticated(false)
          setLoading(false)
        }
      });
    })

    

    
  }, [dispatch]);

  const renderSalesmanRoute = () => {
    return(
      <Switch>
        <Route path="/active_agent" component={Agent} />
        <Route path="/generate_agent_id" component={GenerateAgentID} />
        <Route path="/active_store" component={Store} />
        <Route path="/generate_store_id" component={GenerateStoreID} />
        <Route path="/offers" component={Offers} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/checkout_redeem" component={CheckoutRedeem} />
        <Route path="/faq" component={Faq} />
        <Route path="/request" component={Contact} />
        <Route path="/myorders/:id" component={OrdersDetails} />
        <Route path="/myorders" component={ClientPage} />
        <Route path="/giftredeem/:id" component={RedeemsDetails} />
        <Route path="/giftredeem" component={ClientPage} />
        <Route path="/myprofile" component={ClientPage} />
        <Route path="/myrefunds" component={ClientPage} />
        <Route exact path="/" component={Main} />
        <Route path="/:id" component={Main} />
        
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  const renderStoreRoute = () => {
    return(
      <Switch>
        <Route path="/offers" component={Offers} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/checkout_redeem" component={CheckoutRedeem} />
        <Route path="/faq" component={Faq} />
        <Route path="/request" component={Contact} />
        <Route path="/myorders/:id" component={OrdersDetails} />
        <Route path="/myorders" component={ClientPage} />
        <Route path="/giftredeem/:id" component={RedeemsDetails} />
        <Route path="/giftredeem" component={ClientPage} />
        <Route path="/myprofile" component={ClientPage} />
        <Route path="/myrefunds" component={ClientPage} />
        <Route exact path="/" component={Main} />
        <Route path="/:id" component={Main} />
        
        <Route path="*" component={Error} />
      </Switch>
    )
  }

  const renderUserApprovel = () =>{
    if(props.currentUser.status_users === "active"){

      if(props.currentUser.role === "kol"){
        return(
          <NavigationUser component={renderSalesmanRoute()} />
        )
      }
      else
      {
        return(
          <NavigationUser component={renderStoreRoute()} />
        )
      }
      
    }
    else{
      return(
        <Grid style={{ display: "flex", justifyContent: "center" }}>
          <ApprovalUser/>
        </Grid>
      )
    }
  }


  if(loading){
    return (
    <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70  }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </div>);
  }

 
  if(authenticated){
    
    return (
      <BrowserRouter>
          {props.currentUser ? renderUserApprovel() : <RegisterUser/> }
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ClientLogin} />
          <Route path="*" component={Error} />
        </Switch>
    </BrowserRouter>
  );
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setCurrentSalesman: item => dispatch(setCurrentSalesman(item)),
  setItem: (item, user) => dispatch(setItem(item, user)),
  setRedeemItem: (item, user) => dispatch(setRedeemItem(item, user)),
  setProductItem: item => dispatch(setProductItem(item)),
  setCategoryItem: item => dispatch(setCategoryItem(item))
});

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default withRoot(connect(mapStatetoProps, mapDispatchToProps)(App)
);
