import React, { useEffect }  from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import firebase from "firebase";
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/user/user.selector'

const useStyles = makeStyles((theme) => ({
  img: {
    display: "flex", 
    width: '100%', 
    height: 400,
  }
}));

function Index(props) {

    const classes = useStyles();
    const [rows, setRows] = React.useState([]);

    useEffect(() => {

      let bannerRef = firebase.database().ref("banner");

      bannerRef.once('value', snapshot => {
            if (snapshot.exists()) {
                var allbanner = []
                
                snapshot.forEach(child => {

                    var itemsVal = child.val();
                    allbanner.push(itemsVal);

                })
                
                setRows(allbanner)
            }
        })

    }, [props]);

    return (
        <Grid style={{ width: "100%", marginBottom: 10, height: 440 }}>
            <Carousel fullHeightHover autoPlay >
                    {rows.map((row, index) => {

                      /*if(index === 0){

                        if(props.currentUser.role === "kol"){
                          return(
                            <Card key={"banner"+ index} style={{ width: "100%", height: 400 }}>
                              <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: 400}}>
                                <Typography variant="h6" color="textSecondary" component="p">
                                  {"TARGET THIS MONTH"}
                                </Typography>
                                <Typography variant="h5" color="textSecondary" component="p">
                                  {(props.currentUser.total_sell_products ? props.currentUser.total_sell_products : 0) + " / 10000 box of pods"}
                                </Typography>
                              </Grid>
                            </Card>
                          )
                        }
                        else
                        {
                          return(
                            <Card key={"banner"+ index} style={{ width: "100%", height: 400 }}>
                              <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: 400}}>
                                <Typography variant="h6" color="textSecondary" component="p">
                                  {"TARGET THIS MONTH"}
                                </Typography>
                                <Typography variant="h5" color="textSecondary" component="p">
                                  {(props.currentUser.total_ordered_products ? props.currentUser.total_ordered_products : 0) + " / " + 
                                  (props.currentUser.total_ordered_products && props.currentUser.total_ordered_products > 499 ? "1000 box of pods" : "500 box of pods")}
                                </Typography>
                              </Grid>
                            </Card>
                          )
                        }
                        
                      }*/

                      return(
                      <Card key={"banner"+ index} style={{ width: "100%"}}>
                        <CardMedia
                          className={classes.img}  
                          image={row.url}
                          title={row.file_name}
                        />
                      </Card>)
                    })}
              </Carousel>
              </Grid>
          );

}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(
  mapStateToProps,
  null
)(Index);