import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import {
  TablePagination
} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from './Dialog'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ControlButton from './ControlButton';
import firebase from "firebase";

import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import Description from '@material-ui/icons/Description';

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCurrentUser } from '../../redux/user/user.selector'
import moment from "moment";

//bytesToSize
const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

// Table Sort
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

//Table
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    table: {
      minWidth: "100%",
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
}));

function Index(props) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [addItem, setAddItem] = React.useState('');
  const [order, setOrder] = React.useState(props.order ? props.order : 'desc');
  const [orderBy, setOrderBy] = React.useState(props.orderBy);
  const [rowsOriginal, setRowsOriginal] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [searchtxt, setSearchtxt] = React.useState("");
  const [searchCell, setSearchCell] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [collapse, setCollapse] = React.useState(null);
  const [option, setOptions] = React.useState("");
  const [folderData, setFolderData] = React.useState(null);
  const [userLevel, setUserLevel] = React.useState([]);
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
 
  useEffect(() => {

    let itemRef = firebase.database().ref(props.data);

    itemRef.on('value', snapshot => {
     
      if(snapshot.exists()){

        let items = [];

        if(props.folder){
          snapshot.forEach(child => {
            
            var itemsVal = child.val();

            if(itemsVal.user && props.data === "orders"){
              itemsVal.first_name_orders = itemsVal.user["first_name"]
            }

            if(itemsVal.user && props.data === "orders"){
              itemsVal.last_name_orders = itemsVal.user["last_name"]
            }

            if(itemsVal.uuid && props.data === "orders"){
              itemsVal.uuid = parseInt(itemsVal.uuid).toLocaleString('en-US', {minimumIntegerDigits: 5, useGrouping:false})
            }

            if(props.data === "users"){
              itemsVal.level = parseInt(itemsVal.point_users)
              itemsVal.point_users = parseInt(itemsVal.point_users)
            }
            
            if(itemsVal.folder && 
              itemsVal.folder === props.folder){

                
              if(itemsVal.serial){
                itemsVal.serial = parseInt(itemsVal.serial);
              }

              items.push(itemsVal);
            }
            
          })
        }
        else
        {
          snapshot.forEach(child => {
            var itemsVal = child.val();
            
            if(itemsVal.user && props.data === "orders"){
              itemsVal.first_name_orders = itemsVal.user["first_name"]
            }

            if(itemsVal.user && props.data === "orders"){
              itemsVal.last_name_orders = itemsVal.user["last_name"]
            }

            if(itemsVal.uuid && props.data === "orders"){
              itemsVal.uuid = parseInt(itemsVal.uuid).toLocaleString('en-US', {minimumIntegerDigits: 5, useGrouping:false})
            }

            if(props.data === "users"){
              itemsVal.level = parseInt(itemsVal.point_users)
              itemsVal.point_users = parseInt(itemsVal.point_users)
            }

            if(itemsVal.serial){
              itemsVal.serial = parseInt(itemsVal.serial);
            }
            
            if(!itemsVal.folder){

              if(props.data === "agent"){
                if(itemsVal.kol_in_charge === props.currentUser.id){
                  items.push(itemsVal);
                }
              }
              else if(props.data === "users"){
                if(itemsVal.kol_in_charge === props.currentUser.id){
                  items.push(itemsVal);
                }
              }
              else
              {
                items.push(itemsVal);
              }
              
            }
          })
        }

        if(props.data === "users"){
          let userLevelRef = firebase.database().ref("user_level");

          userLevelRef.once('value', snapshot => {
            let _userLevel = [];
            
            snapshot.forEach(child => {
              var userLevelVal = child.val();
              _userLevel.push(userLevelVal)
            })

            setUserLevel(_userLevel)
          })
          
        }

        setRowsOriginal(items)
        setLoading(false)

      }
      else
      {
        setRowsOriginal([])
        setLoading(false)
      }

      setSelected([])
    });

    setHeadCells(props.headCells ? props.headCells : [])
    setSearchCell(props.searchCell ? props.searchCell : [])
    setSearchValue(props.searchValue ? props.searchValue : "")
    setAddItem(props.addCellsName ? props.addCellsName : [])
    
    return () => {
      itemRef.off()
    };

  }, [props]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){
      
    }
    else
    {
      if (event.target.checked) {
        const newSelecteds = readRows().map((n) => n);
        setSelected(newSelecteds);
  
        if(props.handleSelectedSation){
          props.handleSelectedSation(newSelecteds)
        }
        
        return;
      }else
      {
        if(props.handleSelectedSation){
          props.handleSelectedSation([])
        }
      }
    }
    
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleCheckBoxClick = (event, name) => {

    if(props.mediaOption && (props.mediaOption === "image" || props.mediaOption === "customer" || props.mediaOption === "invoice")){

      const selectedIndex = selected.indexOf(name);

      if (selectedIndex === -1) {
        setSelected([name]);
      }
      else{
        setSelected([])
      }
      
    }
    else 
    {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      
      if(props.handleSelectedSation){
        props.handleSelectedSation(newSelected)
      }
      
      setSelected(newSelected);
    }
    
  };

  //Search
  const onChangeSearchSelect = event => {
    setSearchtxt("")
    setSearchValue(event.target.value)
  }

  const search = event => {
    setSearchtxt(event.target.value)
    setPage(0)
  }

  //Dialog
  const onClickDeleteAll = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Delete all selected items")
    setOptions("deleteAll")
    setDialogDes("Are you sure you want to delete these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickMoveAll = () => {
    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setDialogTitle("Move all selected items")
    setOptions("moveAll")
    setDialogDes("Are you sure you want to move these items?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickDelete = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(true)
    setDialogTitle("Delete")
    setOptions("delete")
    setDialogDes("Are you sure you want to delete this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickInfo = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Info")
    setOptions("info")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickPreview = (row) => {

    if(row.type === "application/pdf")
    {
      window.open(row.url, "_blank")
    }
    else
    {
      setDialogLoading(true)
      setSelectedItem(row)
      setAgreeButton(false)
      setDialogTitle("Preview")
      setOptions("preview")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

  }

  const onClickProofOfPayment = (row) => {

    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle("Proof of Payment")
    setOptions("preview_proof_of_payment")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onMediaReady = (e) => {
    setDialogLoading(false)
  }

  const onClickMove = (row) => {

    setDialogLoading(true)
    let itemRef = firebase.database().ref(props.folder_data);
    
    itemRef.once('value', snapshot => {
        
      if(snapshot.exists()){
        let items = [];
        snapshot.forEach(child => {
            
          var itemsVal = child.val();
          items.push(itemsVal);
          
        })
        
        setFolderData(items)
        setDialogLoading(false)
      }
      else
      {
        setFolderData([])
        setDialogLoading(false)
      }

    })

    setAgreeButton(false)
    setSelectedItem(row)
    setDialogTitle("Move")
    setOptions("move")
    setDialogDes("Are you sure you want to move this item?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const onClickUpload = (row) => {
    setDialogLoading(false)
    setAgreeButton(false)
    setSelectedItem(row)
    setDialogTitle("Upload")
    setOptions("upload")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickEdit = (row) => {
    setDialogLoading(false)
    setAgreeButton(true)
    setSelectedItem(row)
    setDialogTitle("Edit")
    setOptions("edit")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickAddItem = () => {
    setDialogLoading(false)
    setSelectedItem(null)
    setAgreeButton(true)
    setDialogTitle(props.addItemText)
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const onClickOpenFolder = (row) => {
    setDialogLoading(false)
    setSelectedItem(row)
    setAgreeButton(false)
    setDialogTitle(row.name)
    setOptions("openFolder")
    setDialogDes("")
    setFullScreenDialog(true)
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  };

  const selectFolder = (cell) => {
    firebase.database().ref(props.data + '/' + selectedItem.id).update({ folder: cell ? cell.id : null })
    if(selectedItem.folder){
      firebase.database().ref(props.folder_data + "/" + selectedItem.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
    }
    
    setSelected([])
    setOpenDialog(false)
  }

  const selectFolderMoveAll = (cell) => {

    selected.forEach((child, index) => {
      firebase.database().ref(props.data + '/' + child.id).update({ folder: cell ? cell.id : null })
      if(child.folder){
        firebase.database().ref(props.folder_data + "/" + child.folder).update({ updated: firebase.database.ServerValue.TIMESTAMP})
      }
    });

    setSelected([])
    setOpenDialog(false)
  }

  const handleAgree = () => {

    if(option === "deleteAll"){
        selected.forEach((child, index) => {
          firebase.database().ref(props.data + '/' + child.id).remove().then(() => {
            if(props.storage){
              firebase.storage().ref().child(child.full_path).delete().catch((error)=>{

              })
            }
          })

          if(props.type === "folder"){
            firebase.database().ref(props.child).once('value', snapshot => {
              if(snapshot.exists()){
                snapshot.forEach(childval => {
              
                  var itemsVal = childval.val();
                  if(itemsVal.folder && 
                    itemsVal.folder === child.id){
                      firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {
  
                        if(props.storage){
                          firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
             
                          })
                        }
                        
                      });
                      
                  }
                  
                })
              }
            })
            
          }

        });


    }

    else if(option === "delete"){

      firebase.database().ref(props.data + '/' + selectedItem.id).remove().then(() => {
        if(props.storage){
          firebase.storage().ref().child(selectedItem.full_path).delete().catch((error)=>{
           
          })
        }
      
        
        if(props.type === "folder"){
          firebase.database().ref(props.child).once('value', snapshot => {
            if(snapshot.exists()){
              snapshot.forEach(child => {
            
                var itemsVal = child.val();
                if(itemsVal.folder && 
                  itemsVal.folder === selectedItem.id){
                    firebase.database().ref(props.child + '/' + itemsVal.id).remove().then(() => {

                      if(props.storage){
                        firebase.storage().ref().child(itemsVal.full_path).delete().catch((error)=>{
           
                        })
                      }
                      
                    });
                    
                }
                
              })
            }
          })
          
        }

      })
    }    

    else if(option === "edit"){
      firebase.database().ref(props.data + '/' + selectedItem.id).update(selectedItem)
    }    

    setSelected([])
    setOpenDialog(false)
  }

  const onChangeHandle = (e, id) => {
    setSelectedItem({ ...selectedItem, [id]: e.target.value });
  }

  const onChangeAddItemHandle = (e, id) => {
    setAddItem({ ...addItem, [id]: e.target.value });
  }

  const readRows = () => {
    var filterData = rowsOriginal.filter(item => { 
      
      if(item[searchValue] !== undefined){

        return item[searchValue].toString().toLowerCase().includes(searchtxt.toLowerCase())
 
      }
      else
      {
        return undefined
      }
    });

    if(searchtxt !== ""){
        return filterData
    }
    else{
        return rowsOriginal
    }
    
    
  }

  if(loading){
    return (
    <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
      <CircularProgress 
        disableShrink
        color="primary" />
    </Grid>);
  }

  return (
    <Grid>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          selectedItem={selectedItem}
          addItem={addItem}
          onChangeHandle={(e, id) => onChangeHandle(e, id)}
          onChangeAddItemHandle={(e, id) => onChangeAddItemHandle(e, id)}
          handleAgree={handleAgree}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          folderData={folderData}
          selectFolder={selectFolder}
          selectFolderMoveAll={selectFolderMoveAll}
          handleClose={handleClose}
          onMediaReady={onMediaReady}
          {...props}/>
          
        <EnhancedTableToolbar 
          onClickMoveAll={onClickMoveAll}
          numSelected={selected.length} 
          selected={selected}
          onClickAddItem={onClickAddItem}
          onClickDeleteAll={onClickDeleteAll}
          {...props}/>
        <TableContainer>
        <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            >
            <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={readRows().length}
                headCells={headCells}
                {...props}
            />
            
            <TableBody>
                <TableRow>
                    <TableCell padding={'none'} colSpan={props.headCells.length+1+(props.control ? 1 : 0)}>
                      <Grid container>
                          <Grid item md={7} xs={12}>
                          <form noValidate autoComplete="off">
                          <FormControl style={{ marginTop:10 }} fullWidth >
                              <InputLabel style={{ marginLeft:20 }}>Search</InputLabel>
                              <Input disabled={rowsOriginal.length > 0 ? false : true} onChange={(e) => search(e)}
                                  value={searchtxt}
                                  id="input-with-icon-adornment"
                                  startAdornment={
                                      <InputAdornment style={{ marginLeft:20 }} position="start">
                                      <SearchIcon />
                                      </InputAdornment>
                                  }
                              />
                          </FormControl>
                          </form>
                          </Grid>
                          <Grid item md={5} xs={12} style={{ marginTop:10 }}>
                          <FormControl style={{ width: "100%"}} >
                              <InputLabel style={{ paddingLeft: 25 }} id="simple-select-outlined-label">Search by</InputLabel>
                              <Select
                                  style={{ paddingLeft: 18 }} 
                                  disabled={rowsOriginal.length > 0 ? false : true}
                                  labelId="simple-select-outlined-label"
                                  id="simple-select-outlined"
                                  value={searchValue}
                                  label="Search by"
                                  onChange={(e) => onChangeSearchSelect(e)}
                              >
                                  {searchCell.map((cell, index)=>{
                                      const labelId = `enhanced-search-${index}`;
                                      return(
                                          <MenuItem key={labelId} value={cell.id}>{cell.label}</MenuItem>
                                      )
                                  })}
                              </Select>
                          </FormControl>
                          </Grid>
                      </Grid>
                    </TableCell>
                </TableRow>
                {stableSort(readRows(), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const rolllabelId = `enhanced-table-roll-${index}`;
                    
                    return (
                      <React.Fragment key={rolllabelId}>
                        <TableRow
                          hover
                          style={{ opacity:1 }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected} 
                          >
                          <TableCell padding="checkbox">
                              <Checkbox
                                style={{ display: props.listOnly ? "none" : "normal"}} 
                                onClick={(event) => handleCheckBoxClick(event, row)}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                          </TableCell>
                          {headCells.map((cell, cellIndex) => {
                              const labelCellId = `enhanced-table-cell-${cellIndex}`;
                              
                              if(cell.id === "created" || cell.id === "updated" ){
                                return(
                                  row[cell.id] ? <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {new Intl.DateTimeFormat('en-GB', {year: 'numeric',day: '2-digit',  month: 'long' ,hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }).format(row[cell.id])}
                                  </TableCell> : <TableCell key={labelCellId} style={{ padding: 3 }} align="left"></TableCell>)
                              }
                              else if(cell.id === "size"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                  {bytesToSize(row[cell.id])}
                                  </TableCell>)
                              }
                              else if(cell.id === "url"){

                                if(row["type"] === "video/mp4"){
                                  return(
                                    <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                      <VideoLibraryIcon color="primary" style={{ fontSize: 36}}/>
                                    </TableCell>)
                                }

                                if(row["type"] === "application/pdf"){
                                  return(
                                    <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                      <Description color="primary" style={{ fontSize: 36}}/>
                                    </TableCell>)
                                }

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] ? <img
                                    src={row[cell.id]}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row["name"]}
                                    /> : null }
                                  </TableCell>)
                              }
                              else if(cell.id === "image"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] && row[cell.id][0] ? <img
                                    src={row[cell.id] ? row[cell.id][0].url : null}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row["name"]}
                                    />: null}
                                  </TableCell>)
                              }
                              else if(cell.id === "gallery"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    {row[cell.id] && row[cell.id][0] ? <img
                                    src={row[cell.id][0] ? row[cell.id][0].url : null}
                                    style={{  display: 'block', width: 'auto', maxHeight: 50, maxWidth: 50 }}
                                    alt={row[cell.id][0] ? row[cell.id][0].name : ''}
                                    />: <Typography  style={{ wordWrap: "break-word" }} variant="body2" >-</Typography>}
                                  </TableCell>)
                              }
                              else if(cell.id === "status"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: row[cell.id] === "draft" ? "#919191" : "#99d700" }} variant="body2" >{row[cell.id].toUpperCase()}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "status_users"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: row[cell.id] === "inactive" ? "#919191" : "#99d700" }} variant="body2" >{row[cell.id].toUpperCase()}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "status_orders"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word", color: "#99d700" }} variant="body2" >{row[cell.id]}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "expired"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{moment.unix(row[cell.id]).format("DD-MM-YYYY")}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "amount" || cell.id === "paid_total"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "RM " + row[cell.id] : ""}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "highest_level"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Yes" : "-"}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "uuid"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id]}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              else if(cell.id === "proof_of_payment"){
                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography variant="body2">{row[cell.id] ? "Received" : 'Not received'}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }

                              else if(cell.id === "level"){

                                let level = "";
                                let highest_point = 0;
                                
                                userLevel.forEach(val => {
                                  if(val.max_point >= highest_point){
                                    highest_point = val.max_point;
                                  }
                                })

                                userLevel.forEach(val => {
                                  if(parseInt(row["point_users"]) >= val.min_point && parseInt(row["point_users"]) < val.max_point){
                                    level = val.name;
                                  }
                                  if(parseInt(row["point_users"]) >= highest_point && val.highest_level){
                                    level = val.name;
                                  }
                                  
                                })

                                return(
                                  <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                    <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                      <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{level}</Typography>
                                    </Grid>
                                  </TableCell>)
                              }
                              
                              return(
                              <TableCell key={labelCellId} style={{ padding: 3 }} align="left">
                                <Grid style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: 200}} >
                                  <Typography  style={{ wordWrap: "break-word" }} variant="body2" >{row[cell.id]}</Typography>
                                </Grid>
                              </TableCell>)
                          })}
                          {props.control && matches? 
                            <TableCell key={"control"} style={{ padding: 3 }} align="right">
                                <ControlButton 
                                  row={row}
                                  onClickPreview={(row)=> onClickPreview(row)} 
                                  onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                  onClickMove={(row)=> onClickMove(row)} 
                                  onClickInfo={(row)=> onClickInfo(row)}
                                  onClickEdit={(row)=> onClickEdit(row)}
                                  onClickUpload={(row)=> onClickUpload(row)}
                                  onClickDelete={(row)=> onClickDelete(row)}
                                  onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                  {...props}
                                />
                          </TableCell> : null}

                            {matches ? null : 
                            <TableCell align="right">
                              <IconButton onClick={() => { 
                                  if(collapse === row.id){
                                    setCollapse(null)
                                  }
                                  else
                                  {
                                    setCollapse(row.id)
                                  }
                              }}>
                                {collapse === row.id? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </TableCell>}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headCells.length+1+(props.control ? 1 : 0)}>
                              <Collapse in={collapse === row.id ? true : false } timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                      <ControlButton 
                                        row={row}
                                        onClickPreview={(row)=> onClickPreview(row)} 
                                        onClickProofOfPayment={(row)=> onClickProofOfPayment(row)} 
                                        onClickMove={(row)=> onClickMove(row)} 
                                        onClickInfo={(row)=> onClickInfo(row)}
                                        onClickEdit={(row)=> onClickEdit(row)}
                                        onClickUpload={(row)=> onClickUpload(row)}
                                        onClickDelete={(row)=> onClickDelete(row)}
                                        onClickOpenFolder={(row)=> onClickOpenFolder(row)}
                                        {...props}
                                      />
                                  </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                    labelRowsPerPage={"Pages"}
                    rowsPerPageOptions={[10, 25, 50]}
                    colSpan={props.headCells.length+1+(props.control ? 1 : 0)}
                    count={readRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>

            </TableFooter>
        </Table>
        </TableContainer>
          
    </Grid>
  );
}

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index)